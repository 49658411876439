<div class="wrapper">

    <div class="content-wrap">

        <p class="status {{settings.user.employment_status}}">Current Status: 'Employed'</p>
        <!-- Change 'class=" status available"' to 'class=" status employed"' to switch status -->

        <!-- Main Content Starts HERE -->
        <div class="main-content">

            <div class="welcome">
                <h2>Welcome</h2>
                <p>{{settings.user.about_me}}</p>
            </div>

            <div class="divider"></div>

            <div class="technical-skills">
                <h2>Technical Skills</h2>
                <p>{{settings.user.technical_intro}}</p>
                <ul>
                    <li *ngFor="let skill of settings.user.skills">
                        <p>{{skill.name}} <span>{{skill.years_of_experience}} years</span></p>
                    </li>
                </ul>
                <div class="cleaner"></div>
            </div>

            <div class="divider"></div>

            <div class="certifications">
                <h2>Certifications</h2>
                <ul>
                    <li *ngFor="let cert of settings.user.certifications">
                        <a [href]="cert.url" target="_blank">{{cert.name}} <!--<span>{{cert.date}}</span>--></a>
                    </li>
                </ul>
                <div class="cleaner"></div>
            </div>

            <div class="divider"></div>

            <div class="work-experience">
                <h2>Work Experience</h2>
                <p>I've worked in several places, where I have learned more than just technical knowledge, like how
                    to treat clients and the proper way to express myself.</p>
                <div class="accordion-wrap" id="list1a">
                    <div class="item-wrap" *ngFor="let experience of settings.user.experience">
                        <a href="#" class="item-title"><strong>{{experience.position}}</strong> -
                            {{experience.work_place}}
                            <span>{{experience.start_date}} - {{experience.end_date || 'Present'}}</span></a>
                        <div class="item-copy">
                            <p>{{experience.job_description}}</p>
                        </div>
                    </div>
                    <div class="cleaner"></div>
                </div>
            </div>

            <div class="divider"></div>

            <!-- <div class="portfolio">
              <h2>Portfolio</h2>
              <p>Integer dolor leo, facilisis non sollicitudin ut, viverra ac ligula. Proin sed erat tincidunt
                  quam blandit tempor elementum id tortor. Mauris et massa sed arcu pellentesque auctor.</p>
              <div class="portfolioCarousel">
                  <div class="carouselWrapper">
                      <ul>
                          <li><a href="images/portfolio/item1.jpg" rel="portfolio_items"
                                  title="Enter a caption here for item 1"></a>
                              <img src="images/portfolio/item1-thumb.jpg" alt="Project Name" /></li>
                          <li><a href="images/portfolio/item2.jpg" rel="portfolio_items"
                                  title="Enter a caption here for item 2"></a>
                              <img src="images/portfolio/item2-thumb.jpg" alt="Project Name" /></li>
                          <li><a href="images/portfolio/item3.jpg" rel="portfolio_items"
                                  title="Enter a caption here for item 3"></a>
                              <img src="images/portfolio/item3-thumb.jpg" alt="Project Name" /></li>
                          <li><a href="images/portfolio/item4.jpg" rel="portfolio_items"
                                  title="Enter a caption here for item 4"></a>
                              <img src="images/portfolio/item4-thumb.jpg" alt="Project Name" /></li>
                          <li><a href="images/portfolio/item5.jpg" rel="portfolio_items"
                                  title="Enter a caption here for item 5"></a>
                              <img src="images/portfolio/item5-thumb.jpg" alt="Project Name" /></li>
                          <li><a href="images/portfolio/item6.jpg" rel="portfolio_items"
                                  title="Enter a caption here for item 6"></a>
                              <img src="images/portfolio/item6-thumb.jpg" alt="Project Name" /></li>
                          <li><a href="images/portfolio/item7.jpg" rel="portfolio_items"
                                  title="Enter a caption here for item 7"></a>
                              <img src="images/portfolio/item7-thumb.jpg" alt="Project Name" /></li>
                          <li><a href="images/portfolio/item8.jpg" rel="portfolio_items"
                                  title="Enter a caption here for item 8"></a>
                              <img src="images/portfolio/item8-thumb.jpg" alt="Project Name" /></li>
                          <li><a href="images/portfolio/item9.jpg" rel="portfolio_items"
                                  title="Enter a caption here for item 9"></a>
                              <img src="images/portfolio/item9-thumb.jpg" alt="Project Name" /></li>
                      </ul>
                  </div>
              </div>
              <div class="cleaner"></div>
          </div>

          <div class="divider"></div> -->

            <div class="education">
                <h2>Education</h2>
                <p>My professional education.</p>
                <div class="accordion-wrap" id="list1c">
                    <div class="item-wrap" *ngFor="let education of settings.user.education">
                        <a href="#" class="item-title"><strong>{{education.career_level}}
                                {{education.career_name}}</strong>
                            - {{education.school_name}} <span> {{education.start_date}} -
                                {{education.start_date}}</span></a>
                        <div class="item-copy">
                            <p>{{education.career_description}}</p>
                        </div>
                    </div>
                    <div class="cleaner"></div>
                </div>
            </div>

            <div class="footer">
                <ul>
                    <li class="valid-html"><a href="http://validator.w3.org/check?uri=www.blackjair.com"
                            target="_blank">Valid xHTML</a></li>
                    <li class="to-top"><a href="#">Back to Top</a></li>
                </ul>
                <div class="cleaner"></div>
            </div>

        </div>
        <!-- Main Content Ends HERE -->

        <!-- SideBar Starts HERE -->
        <div class="sidebar">
            <div class="floating-div">
                <div class="mug-shot">
                    <a id="enlargeImage" href="./assets/images/this-is-me-small.jpg" title="click to enlarge"></a>
                    <img src="./assets/images/this-is-me.jpg" alt="Luis Aguilar" />
                    <h1>{{settings.user.display_name}}</h1>
                    <h3>{{settings.user.current_position}}</h3>
                </div>

                <div class="contact-details">
                    <a class="email">{{settings.user.contact_info.email}}</a>
                    <address>
                        {{settings.user.contact_info.city}}<br />
                        {{settings.user.contact_info.state}}<br />
                        {{settings.user.contact_info.country}}
                    </address>
                    <p class="phone">{{settings.user.contact_info.phone}}</p>
                </div>

                <div class="print-save">
                    <ul>
                        <li><a href="#" onclick="window.print(); return false;" class="print">Print a Hard Copy</a></li>
                        <li><a href="./assets/files/CVLAAR.pdf" target="_blank" class="download">Download in PDF
                                format</a></li>
                    </ul>
                </div>

                <div class="social-media">
                    <p>Find me on...</p>
                    <ul *ngFor="let social of settings.user.social_media">
                        <li><a [href]="social.url" target="_blank" [class]="social.name">My Delicious Bookmarks</a></li>
                    </ul>
                    <div class="cleaner"></div>
                </div>
            </div>
        </div>
        <!-- SideBar End HERE -->

        <div class="cleaner"></div>
    </div>

</div>