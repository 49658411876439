import { Injectable } from '@angular/core';

@Injectable()
export class SettingsService {
    public user: any;
    public app: any;

    constructor() {
        // Set App Settings
        // --------------------------------------
        this.app = {
            name: 'CV Luis Aguilar',
            starting_work_year: '2014'
        };

        this.user = {
            display_name: 'Luis Aguilar',
            current_position: 'Fullstack Developer',
            technical_intro: `In the past ${new Date().getFullYear() - this.app.starting_work_year} years, I have used multiple technologies and frameworks, some of those in which I've keep working are these listed below.`,
            employment_status: 'employed',
            about_me: "I'm a Fullstack Developer, who have worked with multiple technologies and frameworks, always trying to find new ways to resolve every-day issues.",
            
            contact_info: {
                email: 'luis.aguilar.rocha@gmail.com',
                phone: '+52 (662) 205-0973',
                city: 'Hermosillo',
                state: 'Sonora',
                country: 'México'
            },
            social_media: [
                {
                    name: 'twitter',
                    url: 'https://twitter.com/BlackJairs'
                },
                {
                    name: 'facebook',
                    url: 'https://www.facebook.com/BlackJair'
                },
                {
                    name: 'linkedin',
                    url: 'https://www.linkedin.com/in/blackjair/'
                }
            ],
            skills: [
                {
                    name: 'AWS',
                    years_of_experience: 2
                },
                {
                    name: 'Javascript',
                    years_of_experience: 6
                },
                {
                    name: 'AngularJS',
                    years_of_experience: 2
                },
                {
                    name: 'C#',
                    years_of_experience: 3
                },
                {
                    name: 'NodeJS',
                    years_of_experience: 4
                },
                {
                    name: 'Angular',
                    years_of_experience: 3
                },
                {
                    name: 'SQL Engines',
                    years_of_experience: 3
                },
                {
                    name: 'No-SQL Engines',
                    years_of_experience: 1
                }
            ],
            certifications: [
                {
                    name: 'Scrum Team Member',
                    date: '2019/04/29',
                    url: 'https://www.scrum-institute.org/certifications/Scrum-Institute.org-STMAC1346c0110c-89435402298811.pdf'
                },
                {
                    name: 'Exam 483: Programming in C#',
                    date: '2019/10/03',
                    url: 'https://www.credly.com/badges/dddd09f2-2ac9-4d6e-ad35-f93059535672'
                },
                {
                    name: 'AWS Certified Developer – Associate',
                    date: '2022/11/15',
                    url: 'https://www.credly.com/badges/0f4fcbd3-3a8f-43f8-a6a4-4db428718e02'
                }
            ],
            experience: [
                {
                    position: 'Fullstack Developer',
                    work_place: 'OkBoy',
                    start_date: '2021/09/17',
                    job_description: `Fullstack Developer in charge of developing new tools and new solutions, also helping rebuild the architecture and research/implement new tools`
                },
                {
                    position: 'Senior Programmer',
                    work_place: 'Grupo Tress Internacional',
                    start_date: '2020/06/25',
                    end_date: '2021/09/17',
                    job_description: `Responsable for researching and applying new tools and technologies to develop a new solution with a new team. Most tools researched are AWS Services and ReactJS development.`
                },
                {
                    position: 'Medium Programmer',
                    work_place: 'Grupo Tress Internacional',
                    start_date: '2017/12/21',
                    end_date: '2020/06/25',
                    job_description: `My job is to create new features to a web and app based solution for maquila employees, where they can see and download their payrolls, invoices, apply for vacations, ask for clarifications and anything from their HR departmant.

                    The main technologies used in this solutions, are C# based RestAPI and AngularJS in the front-end.`
                },
                {
                    position: 'Developer / Analyst',
                    work_place: 'CT Internacional',
                    start_date: '2016/11/14',
                    end_date: '2017/12/15',
                    job_description: `While I worked here, I was in a team dedicated to create a new platform to unify a series of satelllite apps to interact with a bigger (and somewhat obsolete) ERP, like reports or implmeneting new bussiness logic.

                    Several technologies were used to accoplish that, NodeJS was the main back-end language, but PHP and Python was also used, and Angular, JavaScript/jQuery in the front-end; also, MongoDB, MySQL and SQLServer were used as DB engines.`
                },
                {
                    position: 'Developer',
                    work_place: 'div.mx / Freelancer',
                    start_date: '2016/01/21',
                    end_date: '2016/11/11',
                    job_description: `I helped to finish a resource managing project to a non-public company, I used C#/JavaScript and SQLServer for development.`
                },
                {
                    position: 'Developer',
                    work_place: 'SpecialCompu',
                    start_date: '2014/01/21',
                    end_date: '2016/01/29',
                    job_description: `I was part of a team formed to develop an ERP from scratch, from interviewing the client to relasing beta versions.

                    I used PHP in back-end and a KnockoutJS library for the front-end.`
                }
            ],
            portfolio: [],
            education: [
                {
                    school_name: 'UTH',
                    career_level: 'T.S.U.',
                    career_name: 'Tecnologías de la Información y Comunicación',
                    career_description: `College career based on most used technologies, they have three branches: networking, e-commerce and development, from where I choose Development as my main carrer.`,
                    start_date: '01/09/2010',
                    end_date: '01/09/2012'
                }
            ]
        };
    }
}